import { render, staticRenderFns } from "./commentStats.vue?vue&type=template&id=4cd4d7e8&scoped=true&"
import script from "./commentStats.vue?vue&type=script&lang=ts&"
export * from "./commentStats.vue?vue&type=script&lang=ts&"
import style0 from "./commentStats.vue?vue&type=style&index=0&id=4cd4d7e8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cd4d7e8",
  null
  
)

export default component.exports