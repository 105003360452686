







/* tslint:disable:no-console */
import { Component, Prop, Vue } from 'vue-property-decorator';
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: 'ErrBlock',
})
export default class ErrBlock extends Vue {
  @Prop({default: '无网络连接，请检查网络设置'}) private text!: string;
  private img: string = require('assets/images/err_block.png');
}
