








































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Stars from "@/components/widget/stars.vue";
@Component({
  name: "CommentStats",
  components: { Stars }
})
export default class TabContainer extends Vue {
  @Prop() private gameInfo!: any;
  private color: any[] = [
    "#55ba30",
    "#addc44",
    "#fede36",
    "#ed9932",
    "#e34b4a"
  ];
  get getPercent(): any[] {
    const self: any = this;
    const arr = (self.gameInfo.star_percent || [])
    return arr.slice(0).reverse()
  }
  get avgScore(): any {
    const self: any = this;
    if (self.gameInfo.avg_score !== 10) {
      return parseFloat(self.gameInfo.avg_score).toFixed(1);
    } else {
      return self.gameInfo.avg_score;
    }
  }
}
