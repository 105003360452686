



















































































































































/* tslint:disable:no-console */
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import BlockBox from "@/components/common/Block-box.vue";
import ImgBlockNormal from "@/components/block/Img-block-normal.vue";
import Comment from "@/components/widget/comment.vue";
import Coupon from "@/components/widget/coupon.vue";
import Gift from "@/components/widget/gift.vue";
import NewsList from "@/components/block/News-list.vue";
import MoreText from "@/components/widget/moreText.vue";
import CommentStats from "@/components/widget/commentStats.vue";
import ErrBlock from "@/components/widget/err-block.vue";
import mixins from "@/mixin/index.ts";

import { TabContainer, TabContainerItem } from "mint-ui";
import { getHomePageData } from "@/api";

// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "TabContainer",
  components: {
    BlockBox,
    ImgBlockNormal,
    Comment,
    Coupon,
    Gift,
    [TabContainer.name]: TabContainer,
    [TabContainerItem.name]: TabContainerItem,
    NewsList,
    MoreText,
    CommentStats,
    ErrBlock
  },
  mixins: [mixins]
})
export default class MTabContainer extends Vue {
  @Prop() private data!: any;
  private tabsHead: any[] = [
    { name: "详情", id: "tab1", show: true },
    { name: "福利", id: "tab2", show: true },
    { name: "评论", id: "tab3", show: true },
    { name: "资讯", id: "tab4", show: true }
  ];
  private tabActive: string = "tab1";
  private fatInfo: any = {
    comments: {},
    coupons: [],
    gifts: [],
    news: {}
  };
  private comments: any = {};
  private news: any = {};
  private commentType: string = 'score';  // time

  private dataMore1: boolean = true;
  private dataMore2: boolean = true;
  private loadStatus1: string = "";
  private loadStatus2: string = "";
  private pageMax: number = 20;
  private currIndex1: number = 0;
  private currIndex2: number = 0;
  private stopComment: boolean = false; // 是否关闭评论

  private clickTab(id: any, swipe?: boolean): void {
    const self: any = this;
    // 点击需要赋值
    this.tabActive = id || "tab1";
  }
  get getFatInfo(): any {
    const self: any = this;
    let data: any = this.data;
    // 添加对数据的判断和处理，来控制是否显示模块
    data = Object.assign({}, data, {
      comments: data.comments instanceof Object ? data.comments : {},
      coupons: data.coupons instanceof Array ? data.coupons : [],
      gifts: data.gifts instanceof Array ? data.gifts : [],
      news: data.news instanceof Object ? data.news : {}
    });
    this.fatInfo.comments = data.comments
    this.fatInfo.news = data.news

    this.tabsHead[1].show =
      data.welfare && !!(data.coupons.length || data.gifts.length);
    this.tabsHead[2].show = data.show_evaluate;
    this.tabsHead[3].show = data.show_information;
    self.dataMore1 = data.comments.more
    self.dataMore2 = data.news.more
    return data
  }
  @Watch('tabActive')
  private onChangeTabActive(val: any, old: any) {
    const self: any = this;
    if (val === 'tab3') {
      self.loadStatus2 = "end" + val
      // 如果props有评论数据，就重置加载更多，否则第一次请求数据
      if (self.fatInfo.comments.data.length) {
        self.loadStatus1 = "reset"
      } else {
        self.getCommentData()
      }
    } else if (val === 'tab4') {
      self.loadStatus1 = "end" + val
      // 如果有第一次的数据
      if (self.fatInfo.news.data.length) {
        self.loadStatus2 = "reset"
      } else {
        self.getNewsData()
      }
    } else {
      self.loadStatus1 = "end" + val
      self.loadStatus2 = "end" + val
    }
  }
  private commentMoreClick() {
    // 互相跳转
    this.clickTab('tab3')
  }
  private getCommentData(more?: string) {
    const self: any = this;
    const appId: number = Number(self.utils.getUrlParam("contentId")) || 0;
    if (!more) {self.$doMotion(self, "LOADING_SWITCH", true)}
    this.$store
      .dispatch("fetchAppComment", {
        context: this,
        type: self.commentType,
        params: { game_id: appId, start: self.currIndex1, max: self.pageMax }
      })
      .then(res => {
        if (!res.value) {
          self.stopComment = true
          self.$doMotion(self, "LOADING_SWITCH", false);
          return
        }
        const block = res.value;
        self.fatInfo.comments.data = self.fatInfo.comments.data.concat(
          block.data
        );
        self.fatInfo.comments.total = block.total;
        self.dataMore1 = block.more;
        // 第一次加载和加载更多
        if (more) {
          self.loadStatus1 = "loaded" + self.currIndex1;
        } else {
          self.loadStatus1 = "reset"
          self.$doMotion(self, "LOADING_SWITCH", false);
        }
      })
      .catch(err => {
        // 暂时关闭评论
        if (Number(err.code || 0) === 123103) {
          self.stopComment = true
        } else {
          self.$toast.show(err);
        }
        self.loadStatus1 = "end" + self.currIndex1;
        self.$doMotion(self, "LOADING_SWITCH", false);
      });
  }
  private getNewsData(more?: string) {
    const self: any = this;
    const appId: number = Number(self.utils.getUrlParam("contentId")) || 0;
    if (!more) {self.$doMotion(self, "LOADING_SWITCH", true)}
    this.$store
      .dispatch("fetchAppNews", {
        context: this,
        params: { appId, start: self.currIndex2, max: self.pageMax, status: 50 }
      })
      .then(res => {
        const block = res.value;
        self.fatInfo.news.blockLink = 'newsDetail'
        self.fatInfo.news.data = self.fatInfo.news.data.concat(
          block
        );
        self.dataMore2 = block.more;
        if (more) {
          self.loadStatus2 = "loaded" + self.currIndex2;
        } else {
          self.loadStatus2 = "reset"
          self.$doMotion(self, "LOADING_SWITCH", false);
        }
      })
      .catch(err => {
        self.$toast.show(err);
        self.loadStatus2 = "end" + self.currIndex2;
      });
  }
  private loadMoreHandler1() {
    const self: any = this;
    if (!self.dataMore1) {
      self.loadStatus1 = "complete" + self.currIndex1;
      return;
    }
    // 加载更多
    self.currIndex1 += self.pageMax;
    self.getCommentData("more");
  }
  private loadMoreHandler2() {
    const self: any = this;
    if (!self.dataMore2) {
      self.loadStatus2 = "complete" + self.currIndex2;
      return;
    }
    // 加载更多
    self.currIndex2 += self.pageMax;
    self.getNewsData("more");
  }
  private switchComment() {
    const self: any = this;
    if (self.stopComment) {
      return
    }
    self.commentType = self.commentType === 'score' ? 'time' : 'score'; // 切换排序
    // 清空加载更多状态
    self.loadStatus1 = "end" + self.commentType;
    self.dataMore1 = true;
    self.currIndex1 = 0;
    self.fatInfo.comments.data = [];  // 清空
    self.getCommentData();  // 切换后第一次加载
  }
}
