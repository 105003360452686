























/* tslint:disable:no-console */
import { Component, Vue } from "vue-property-decorator";

import NavBar from "@/components/Nav-bar.vue";
import BlockBox from "@/components/common/Block-box.vue";
import AppBlockGrade from "@/components/block/App-block-grade.vue";
import TabContainer from "@/components/TabContainer.vue";
import BottomDownload from "@/components/widget/bottomDownload.vue";
import InstallBtn from "@/components/InstallBtn.vue";
import updateTdk from "@/utils/tdk";
import mixins from "@/mixin";
import routerMixin from "@/mixin/router-mixin.ts";
// Vue.component(TabContainer.name, TabContainer);
// Vue.component(TabContainerItem.name, TabContainerItem);

// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "GameDetails",
  components: {
    NavBar,
    BlockBox,
    AppBlockGrade,
    TabContainer,
    InstallBtn
  },
  mixins: [mixins, routerMixin]
})
export default class GameDetails extends Vue {
  // 初始数据可以直接声明为实例的属性
  private info: any = {};
  private navs: any[] = [{ name: "游戏详情", link: "" }];
  private active: string = "tab-container1";
  private gameName: string = '';
  private tdk(self: any) {
    return {
      title: `-${self.gameName}`
    };
  }
  private serverPrefetch() {
    return this.fetchData();
  }
  get getInfo(): any {
    return this.$store.state.pageDatas.gameDetailData || {};
  }
  private fetchData(): any {
    const self: any = this;
    // this.$store.commit('setGameDetailData', {})
    const appId: number = Number(self.$route.query.contentId) || 0;
    // 获取详情数据
    self.$doMotion(self, "LOADING_SWITCH", true);
    return this.$store
      .dispatch("fetchAppDetail", {
        context: this,
        params: { appId, start: 0, max: 50 }
      })
      .then(async (data: any) => {
        const result = data.value;
        self.gameName = data.value.name // 获取到当前的游戏名
        if (result) {
          result.data_source = "gameDetail";
          result.havData = false;
          // self.info = result;
          let task: any[] = [];
          // 是否需要请求评论
          if (result.evaluate_count !== 0 && (!result.evaluates || result.evaluates.length === 0)) {
            task = task.concat([
              this.$store.dispatch("fetchAppComment", {
                context: this,
                type: "score",
                params: { game_id: appId, start: 0, max: 20 }
              })
            ]);
          } else {
            task.push(null);
          }
          // 是否需要请求福利
          if (result.welfare) {
            task = task.concat([
              this.$store.dispatch("fetchAppGift", {
                context: this,
                appId,
                params: { start: 0, max: 20 }
              }),
              this.$store.dispatch("fetchAppCoupon", {
                context: this,
                params: { app_id: appId, start: 0, max: 20 }
              })
            ]);
          }
          // 请求额外数据
          await Promise.all(task).then(vals => {
            result.havData = true;
            const assembleParams = self.assembleParams(result, "click");
            let comments = {
              data: [],
              data_source: "gameDetail",
              ...assembleParams
            }; // 评论,需要跳转更多，所以需要加工
            if (vals[0]) {
              comments = Object.assign({}, vals[0].value, comments);
            }
            result.comments = comments;
            result.news = {
              data: [],
              data_source: "gameDetail",
              ...assembleParams
            }; // 评论,需要跳转更多，所以需要加工
            if (result.welfare) {
              result.gifts = vals[1].value.gift_list || []; // 礼包
              result.coupons = vals[2].value.list || []; // 优惠券
            }
            // self.info = result;
            self.$store.commit('setGameDetailData', result) // 修改store
            self.noData = false
            if (self.isServer) {
              self.$forceUpdate()
            }
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
        self.$toast.show(err.message || '服务器错误，请刷新！');
      })
      .finally(() => {
        self.$doMotion(self, "LOADING_SWITCH", false);
        updateTdk(self);
      });
  }
  private mounted(): void {
    const self: any = this;
    const appId: number = Number(self.$route.query.contentId) || 0;
    if (!appId) {
      this.$router.replace({
        name: "index"
      });
      return;
    }
    if (!self.getInfo.data_source) {  // 是否有值
      self.fetchData();
    }
  }
  private destroyed() {
    this.$store.commit('setGameDetailData', {}) // 修改store
  }
}
